$container_space:60px; //페이지별 좌우 기본 여백
$black:#000000;
body {
    color: $black;
    font-family:'Apercu Pro', sans-serif;
    font-weight:300;
    padding: 0px;
    margin: 0px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    word-break: keep-all;
    font-size: 15px;
    background: #F4F3F4;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;   
}


h1,
h2,
h3,
input,
select,
ul,
li,
button {
    margin: 0px;
    padding: 0px 
}

button {
    color: inherit;
    border: none;
    background: none;
}
html{
    scroll-behavior: smooth;
  }
ul,
li {
    list-style: none;
}
a{
    color:inherit;
    text-decoration: none;;
}

.mobile_view{
    display:none;
}


.header{
    position: fixed;
    display:flex;
    padding:0px 20px;
    height:68px;
    width:100%;
    justify-content: space-between;
    align-items: center;
    background-color: $black;
    box-sizing: border-box;
    z-index: 999;
    .logo_section{
        font-size: 0px;
    }
 
    .main_menu{
        display:flex;
        gap:37px;
        a{
            cursor: pointer;
        }
        li{
            font-size:20px;
            color:#fff;
        }
    }
}

.video_section{
    line-height: 0px;
    iframe{
        position:absolute;
        top:0;left:0;width:100%;height:100%;
    }
    img{
        width:100%;
    }
}
.footer{
    display:flex;
    padding:0px 20px;
    height:68px;
    align-items: center;
    justify-content: space-between;
    background-color: $black;
    .ft_copyright{
        font-size:12px;
        font-weight:300p;
        color:rgba(255, 255, 255, 0.5)

    }
}

.contents_wrap{
    padding-top:68px;
    .main{
        &.m1{
            background-size: cover;
            border-bottom: 1px solid #000;
          
            .main_copy{
                margin-left:-2px;
                font-family: 'ITC Avant Garde Gothic Std';
                font-weight: 700;
                padding:90px $container_space 0px;
                font-size: 211px;
                line-height: 179px;
                letter-spacing: -0.05em;
                .kerning1{
                    letter-spacing: -0.09em;
                }
                .kerning2{
                    letter-spacing: -0.02em;
                }
            }
            .sub_copy{
                padding:0px $container_space;
                margin-top:20px;
                font-family: 'Apercu Mono Pro';
                font-weight: 300;
                font-size: 48px;
                line-height: 59px;
                letter-spacing: -0.08em;
                width:1000px;
                .kerning{
                    letter-spacing: -0.16em;
                }
                .kerning2{
                    letter-spacing: -0.03em;
                }
                .kerning3{
                    letter-spacing: -0.02em;
                }
                .kerning4{
                    letter-spacing: -0.10em;
                }
                .kerning5{
                    letter-spacing: -0.12em;
                }
            }
            .col2_container{
                margin-top:153px;
                display:flex;
                border-top:1px solid $black;
                .main_img{
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    width:543px;
                    height:543px;

                }
                .img_desc{
                    border-left:1px solid $black;
                    padding:45px 22% 0px 57px;
                    box-sizing: border-box;
                    flex:1;
                    font-size: 35px;
                    line-height: 49px;
                }
            }
        }
        &.m2{
            border-top:1px solid $black;
            .spec_items{
                .spec_item{
                    display:flex;
                    border-bottom: 1px solid $black;;
                    justify-content: space-between;
                    .spec_contents{
                        padding:50px 0% 50px $container_space; 
                        width:40%;
                        min-width: 700px;
                        .spec_title{
                            font-family: 'Apercu Pro';
                            font-weight: 300;
                            font-size: 64px;
                            line-height: 64px;
                        }
                        .spec_desc{
                            margin-top:112px;
                            font-weight: 300;
                            font-size: 27px;
                            line-height: 40px;
                         
                        }
                    }
                    .spec_img{
                        padding:63px 72px 63px 0px;
                        display:flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
        &.m3{
            height: 520px;
            background: #75FB8D;
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            .main_copy{
                font-weight: 300;
                font-size: 60px;
                line-height: 74px;
                max-width: 1040px;
                
            }
            .copy_writer{
                margin-top:42px;
                font-size: 20px;
                line-height: 27px;
                .cw_name{
                    font-weight: 500;
                }
            }
        }
    }
    .about{
        &.a1{
            position: relative;
            background-size: 100% 65%;
            border-bottom: 1px solid #000;
        
            .main_copy{

                margin-left:-6px;
                padding:55px $container_space 0px 400px;
                font-family: 'ITC Avant Garde Gothic Std';
                font-weight: 700;
                font-size: 130px;
                line-height: 127px;
                letter-spacing: -0.05em;
            }
         
            .col2_container{
                margin-top:300px;
                display:flex;
                border-top:1px solid $black;
                background-color: #F4F3F4;;
                .spec_items{
                    width:720px;
                    display:flex;
                    flex-direction: column;
                    .spec_item{
                        gap:33px;
                        height:230px;
                        border-bottom:1px solid $black;
                        display:flex;
                        padding:0px $container_space;
                        font-family: 'ITC Avant Garde Gothic Std';
                        font-weight: 600; 
                        font-size: 68px;
                        line-height: 70px;
                        letter-spacing: -0.04em;
                        align-items: center;
                        &:last-child{
                            border-bottom: none;
                        }
                        img{
                            width:108px;
                        }
                    }
                }
                .spec_desc{
                    &>div{
                        margin-bottom:58px;
                    }
                    border-left:1px solid $black;
                    padding:45px 11% 0px 57px;
                    box-sizing: border-box;
                    flex:1;
                    font-size: 35px;
                    line-height: 49px;
                    position: relative;
                    img{
                        position: absolute;
                        right:60px;
                        bottom:44px;
                        width:134px
                    }
                    
                }
            }
        }
        &.a2{
            padding:45px $container_space 90px;
            border-top: 1px solid #000;
            .col2_container{
                display:flex;
                justify-content: space-between;
                .main_copy{
                    font-weight: 300;
                font-size: 57px;
                line-height: 72px;
                flex:1;
                }
                img{
                    margin-top:20px;
                }
                
            }
            .spec_items{
                display:flex;
                gap:40px;
                margin-top:150px;
                .spec_item{
                    flex:1;
                    .spec_title{
                        border-top:1px solid $black;
                        border-bottom:1px solid $black;
                        
                        padding:16px 0px;
                        display:flex;
                        align-items: center;
                        gap:17px;
                        font-weight: 300;
                        font-size: 32px;
                      
                        .spec_number{
                            width:38px;
                            height:38px;
                            background-color: #75FB8D;
                            border-radius: 50%;
                            display:flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: 300;
                            font-size: 25px;
                        }
                    }
                }
            }
            .spec_contents{
                margin-top:36px;
                font-weight: 300;
                font-size: 26px;
                line-height: 43px;
                padding-left:39px;
                li{
                    position: relative;
                 
                    &::before{
                        content:'';
                        position: absolute;
                        left:-37px;
                        top:8px;
                        width:22px;
                        height:22px;
                        background: #9E9E9E;
                        border-radius: 50%;;

                    }
                }
            }
        }
        &.a3{
            margin-top:100px;
            height:693px;
            background-size: cover;;
        }
    }
    .solution{
        &.s1{
            background-size: 100% 65%;
            position: relative;
            border-top: 1px solid #000000;
            border-bottom: 1px solid #000000;
            &>.main_copy{
                margin-left:-6px;
                padding:55px $container_space 0px 400px;
                font-weight: 700;
                font-size: 130px;
                line-height: 127px;
                letter-spacing: -0.05em;
                font-family: 'ITC Avant Garde Gothic Std';
            }
          
            .col2_container{
                margin-top:300px;
                display:flex;
                background-color: #F4F3F4;
                border-top:1px solid $black;
                height:693px;
                .main_copy{
                    font-family: 'ITC Avant Garde Gothic Std';
                    font-weight: 600;
                    font-size: 68px;
                    line-height: 70px;
                    letter-spacing: -0.03em;
                    padding:60px $container_space;
                    width:42%;
                    max-width:720px;
                    box-sizing: border-box;
                }
                .main_desc{
                    border-left:1px solid $black;
                    padding:45px 14% 0px 57px;
                    box-sizing: border-box;
                    flex:1;
                    font-size: 35px;
                    line-height: 49px;
                }
            }
        }
        &.s2{
            border-top:1px solid $black;
            padding:45px $container_space 100px;
            border-bottom: 1px solid #000;
            .col2_container{
                display:flex;
                justify-content: space-between;
                .main_copy{
                    font-weight: 300;
                    font-size: 57px;
                    line-height: 72px;
                    width:60%;
                }
                img{
                    margin-top:20px;
                }
                
            }
            .spec_items{
                display:flex;
                gap:40px;
                margin-top:132px;
                .spec_item{
                    flex:1;
                    .spec_header{
                        border-top:1px solid $black;
                        border-bottom:1px solid $black;
                        padding:5px 0px 26px;
                        position: relative;
                        .spec_title{
                            font-size: 48px;
                            line-height: 76px;
                        }
                        .spec_subtitle{
                            margin-top:3px;
                            font-weight: 300;
                            font-size: 32px;
                            line-height: 32px;
                        }
                        img{
                            position: absolute;
                            right:10px;
                            top:50%;
                            &.logo1{
                                width:173px;
                                margin-top:-36px;
                            }
                            &.logo2{
                                width:191px;
                                margin-top:-24px;
                            }
                        }

                    }
                    .spec_contents{
                        padding-top:48px;
                        font-weight: 300;
                        font-size: 26px;
                        line-height: 38px;
                        height:300px;
                        position: relative;
                        .link_items{
                            position: absolute;
                            bottom:0px;
                            right:0px;

                        }
                        ul{
                            padding-left:39px;
                            li{
                                position: relative;
                                line-height: 42px;
                                &::before{
                                    content:'';
                                    position: absolute;
                                    left:-37px;
                                    top:9px;
                                    width:22px;
                                    height:22px;
                                    background: #9E9E9E;
                                    border-radius: 50%;;
            
                                }
                            }
                        }
                        .link_items{
                            margin-top:68px;
                            display:flex;
                            gap:17px;
                            justify-content: flex-end;
                            .link_item{
                                display:flex;
                                justify-content: center;
                                align-items: center;
                                background-color: #75FB8D;;
                                padding:11px 22px;
                                font-size:24px;
                                border-radius: 63px;
                                


                            }
                           }

                    }
                }
            }
        }
      
        &.s3{
            margin-top:100px;
            height:693px;
            background-size: cover;;
        }
    }
    .contact{
        &.c1{
            border-top:1px solid $black;
            height:760px;
            background-size: cover;
            position: relative;
            .main_copy{
                font-family: 'ITC Avant Garde Gothic Std';
                font-weight: 700;
                font-size: 185px;
                line-height: 127px;
                letter-spacing: -0.05em;
                padding:89px $container_space;
                box-sizing: border-box;
                margin-left:-3px;
            }
        }
        &.c2{
            font-family: 'ITC Avant Garde Gothic Std';
            border-top:1px solid $black;
          
            font-size:32px;
            line-height: 40px;
            font-weight:600;
            letter-spacing: -0.04em;
            display:flex;
           
            .c2_left{
                flex:1;
                display:flex;
                flex-direction: column;
                .address{
                
                    flex:1;
                    display:flex;
                    padding:45px $container_space 0px;
                    flex:1;
                    border-right:1px solid $black;
                    box-sizing: border-box;
                }
        
                .career{
                    border-top:1px solid $black;
                    padding:45px $container_space;
                    flex:1;
                }
            }
            img{
                width:50%;
                height:50vw;
                object-fit: cover;
            }
           
        }
        &.c3{
            margin-top:300px;
            height:672px;
            background-size: cover;
        }
      
    }
    .page_title{
        position: absolute;
        top:50px;
        left:$container_space;
        height:67px;
        font-weight: 300;font-size: 32px;
    }
    .tag_items{
        padding:16px $container_space 0px 400px;
        margin-left:-8px;
        display:flex;
        gap:30px;
        flex-wrap: wrap;
        .tag_item{
            font-family: 'Apercu Mono Pro';
            height:78px;
            display:flex;
            align-items: center;
            justify-content: center;
            padding:0px 35px;
            border-radius: 78px;
            border:1px solid $black;
            box-sizing: border-box;
            background-color: #fff;
            font-weight: 300;
            font-size: 40px;
            line-height: 63px;
            letter-spacing: -0.05em;
        }
    }
}
$container_space:20px; //페이지별 좌우 기본 여백
$black:#000000;


@media only screen and (max-width: 900px) {
    .mobile_view{
        display:initial;
    }
    .header{
        padding:0px 16px 0px 12px;
        height:50px;
        .logo_section{
            img{
                width:93px;
            }
        }
        .main_menu{
            position: fixed;
            z-index: 10;
            top:0px;
            bottom:0px;
            left:0px;
            background-color: #75FB8D;
            width:100%;
            box-sizing: border-box;
            opacity: 0;
            pointer-events: none;
            transition-duration: 0.3s;

            display:flex;
            flex-direction: column;
            padding-top:50px;
            gap:0px;
          
            li{
                &:first-child{
                    border-top:1px solid $black;
                }
                font-size:27px;
                line-height: 39px;
                height:60px;
                display:flex;
                align-items: center;
                color:$black;
                padding-left:$container_space;
                border-bottom:1px solid $black;

                &.active{
                    &:after{
                        display:none;
                        opacity: 0;
                    }
                }
                a{
                    width:100%;
                }
                &:hover{
                    &.active,&:hover{
                        &:after{
                            display:none;
                            opacity: 0;
                        }
                    }
                }
            }

        }
        &.active{
            .mobile_menu{
                &>div{
                    background-color: $black;
                }
               
                .mm_bar.mm1{
                    transform: translateY(7.5px) rotate(45deg);
                }
                .mm_bar.mm2{
                    transform: scaleX(0)
                }
                .mm_bar.mm3{
                    transform: translateY(-7.5px) rotate(-45deg);
                }
            }
            .main_menu{
                opacity: 1;
                pointer-events: initial;
            }
         
           
        }
        .mobile_menu{
            display:flex;
            flex-direction: column;
            gap:6px;
            position: relative;
            z-index: 20;
           
            .mm_bar{
                width:26px;
                height:1.5px;
                background-color: #fff;
                transition-duration: 0.2s;
                transform-origin: center center;
            }
        }
    }

    .contents_wrap{
        padding-top:50px;
        .main{
            &.m1{
                .main_copy{
                    padding:29px $container_space 0px;
                    font-size: 72px;
                    line-height: 64px;

                }
                .sub_copy{
                    margin-top:10px;
                    font-size:18px;
                    line-height: 21px;
                    padding:0px $container_space;
                    width:100%;
                    box-sizing: border-box;
                }
                .col2_container{
                    margin-top:80px;
                    flex-direction: column;

                    .main_img{
                        width:50%;
                        height:183px;
                        border-right:1px solid $black;
                        img{
                            width:80%;
                        }
                    }
                    .img_desc{
                        border-top:1px solid $black;
                        border-left:none;
                        padding:20px $container_space;
                        font-size:18px;
                        line-height: 24px;;
                        box-sizing: border-box;
                        height:183px;
                        flex:initial;
                        
                    }

                }
            }
            &.m2{
                .spec_items{
                    .spec_item{
                        height:218px;
                        align-items: flex-start;
                        padding:0px $container_space;
                        gap:10px;
                        .spec_contents{
                            padding:18px 0px 0px;
                            flex:1;
                            width:initial;
                            min-width: initial;
                          
                            .spec_title{
                                font-size:29px;
                                line-height: 37px;;
                            }
                            .spec_desc{
                                margin-top:55px;
                                font-size:15px;
                                line-height: 21px;
                            }
                        
                        }
                        .spec_img{
                            margin-top:23px;
                            padding:0px;
                            img{
                                width:90px;
                            }
                        }
                        
                    }
                }
            }
            &.m3{
                padding:0px $container_space;
                height:336px;
                .main_copy{
                    font-size:29px;
                    line-height: 36px;
                }
                .copy_writer{
                    margin-top:20px;
                    font-size:13px;
                    line-height: 14px;
                }
            }
        }
        .about{
            &.a1{
                background-size: 100% 50%;
                .main_copy{
                    padding:63px $container_space 0px;
                    font-size: 66px;
                    line-height: 58px;

                }
                .col2_container{
                    margin-top:163px;
                    flex-direction: column;

                    .spec_items{
                        width:100%;
                        .spec_item{
                           padding:0px $container_space;
                           height:100px;
                           font-size:31px;
                           line-height: 32px;
                           gap:15px;
                           .spec_title{
                               margin-top:5px;
                           }
                           img{
                               width:46px;
                           }

                        }
                    }
                    .spec_desc{
                        border-top:1px solid #000000;
                        border-left: none;;
                        padding:21px $container_space 112px;
                        font-size:18px;
                        line-height: 24px;
                        img{
                            width:70px;
                            right:27px;
                            bottom:27px;
                        }
                        &>div{
                            margin-bottom:0px;
                        }
                    }
                }
              
            }
            &.a2{
                border-top:1px solid $black;
                padding:20px $container_space  95px;;
                .col2_container{
                    .main_copy{
                        font-size:29px;
                        line-height:37px;
                    }
                    img{
                        margin-top:0px;
                        width:110px;

                    }
                }
                .spec_items{
                    margin-top:50px;
                    flex-direction: column;
                    .spec_item{
                        .spec_title{
                            font-size:17px;
                            gap:16px;
                            padding:0px;
                            height:40px;
                            .spec_number{
                                width:24px;
                                height:24px;
                                font-size:14px;

                            }
                        }
                        .spec_contents{
                            margin-top:20px;
                            font-size:16px;
                            line-height: 26px;
                            li{
                                &::before{
                                    width:15px;
                                    height:15px;
                                    top:4px;
                                    left:-35px;
                                }
                            }
                        }
                    }
                }

            }
            &.a3{
                margin-top:40px;
                height:337px;
            }
        }
        .solution{
            &.s1{
                &>.main_copy{
                    padding:63px $container_space 0px;
                    font-size: 66px;
                    line-height: 58px;
                    br{
                        display:none;
                    }
                }
                .col2_container{
                    margin-top:55px;
                    flex-direction: column;
                    height:initial;
                    .main_copy{
                      font-size:34px;
                      line-height: 36px;  
                      width:100%;
                      padding:28px $container_space 84px;
                    }
                    .main_desc{
                        border-left:none;
                        border-top:1px solid $black;
                        font-size:18px;
                        line-height: 24px;
                        padding:20px $container_space 80px;
                        
                    }
                }
            }
            &.s2{
                padding:20px $container_space 40px;
                .col2_container{
                    align-items: flex-start;
                    .main_copy{
                        font-size:29px;
                        line-height:37px;
                    }
                    img{
                        margin-top:5px;
                        width:117px;

                    }
                }
                .spec_items{
                    margin-top:74px;
                    flex-direction: column;
                    .spec_item{
                        .spec_header{
                            padding:10px 0px;
                               
                            .spec_title{
                                font-size:21px;
                                line-height: 21px;
                                font-weight:400;
                        
                            }
                            .spec_subtitle{
                                font-size:13px;
                                line-height: 21px;
                            }
                            img{
                                right:5px;
                            }
                            img.logo1{
                                width:86px;
                                margin-top:-20px;
                            }
                            img.logo2{
                                width:90px;
                                margin-top:-13px;
                            }
                        }
                      
                        .spec_contents{
                            margin-top:25px;
                            padding-top:0px;
                            font-size:16px;
                            line-height: 22px;
                            height:170px;
                            ul{
                                margin-top:24px;
                                padding-left:28px;
                                li{
                                    line-height: 26px;
                                    &::before{
                                        width:15px;
                                        height:15px;
                                        top:6px;
                                        left:-28px;
                                    }
                                }
                            }
                            .link_items{
                                margin-top:43px;
                                gap:8px;
                                .link_item{
                                    font-size:13px;
                                    padding:6px 15px;
                                }
                            }
                        }
                    }
                }
            }
            &.s3{
                margin-top:50px;
                height:337px;
                background-position: center;
            }
        }

        .contact{
            &.c1{
                height:355px;
                
                .main_copy{
                    padding:25px $container_space;
                    font-size: 55px;
                    line-height: 50px;
                }
            
            }
            &.c2{
               
                font-size:25px;
                line-height: 27px;;
                flex-direction: column;
                .c2_left{
                    height:400px;
                    flex:initial;
                    .address{
                        padding:21px $container_space;
                        border-right:none;
                        
                    }
                    .career{
                        padding:21px $container_space;
                        border-bottom:1px solid $black;
                    }
                }

                img{
                    width:100%;
                    height:initial;
                }
            }
            &.c3{
                margin-top:100px;
                height:337px;
            }
        }
        .page_title{
            top:20px;
            left:$container_space;
            font-size:18px;
            line-height: 18px;
            height:initial;
            padding:0px;
        }
        .tag_items{
            padding:9px $container_space 0px;
            gap:10px;
            .tag_item{
                height:38px;
                font-size:18px;
                padding:0px 15px;
            }           
        }
    }
    .footer{
        padding:0px 15px;
        height:50px;

        img{
            width:170px;
        }
        .ft_copyright{
            font-size:9px;
        }
    }

}
@font-face {
    font-family: 'Apercu Pro';
    src: url('./fonts/apercu-light-pro.eot');
    src: url('./fonts/apercu-light-pro.eot?#iefix') format('embedded-opentype'),
         url('./fonts/apercu-light-pro.woff2') format('woff2'),
         url('./fonts/apercu-light-pro.woff') format('woff'),
         url('./fonts/apercu-light-pro.ttf') format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: 'Apercu Mono Pro';
    src: url('./fonts/apercu-mono-light-pro.eot');
    src: url('./fonts/apercu-mono-light-pro.eot?#iefix') format('embedded-opentype'),
         url('./fonts/apercu-mono-light-pro.woff2') format('woff2'),
         url('./fonts/apercu-mono-light-pro.woff') format('woff'),
         url('./fonts/apercu-mono-light-pro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu Pro';
    src: url('./fonts/apercu-regular-pro.eot');
    src: url('./fonts/apercu-regular-pro.eot?#iefix') format('embedded-opentype'),
         url('./fonts/apercu-regular-pro.woff2') format('woff2'),
         url('./fonts/apercu-regular-pro.woff') format('woff'),
         url('./fonts/apercu-regular-pro.ttf') format('truetype');
    font-weight: 400;
}
@import url("//hello.myfonts.net/count/4a903e");
  
@font-face {
    font-family: 'ITC Avant Garde Gothic Std';
    font-weight:600;
  src: url('./fonts/ITCAvantGardeW1GDemi/font.woff2') format('woff2'), url('./fonts/ITCAvantGardeW1GDemi/font.woff') format('woff');
}
@font-face {
    font-family: 'ITC Avant Garde Gothic Std';
    font-weight:700;
  src: url('./fonts/ITCAvantGardeW1GBold/font.woff2') format('woff2'), url('./fonts/ITCAvantGardeW1GBold/font.woff') format('woff');
}

